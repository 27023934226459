.main-image {
  background-image: url('/images/statue-1823edit-.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 400px;
  width: 100%;
}

/*.main-image2 {
  background-image: url('/images/sky_sun2.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 300px;
  width: 80%;
}*/

.main-image2 {
  background-image: url('/images/sky_sun2.jpg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto;
  background-attachment: fixed;
  height: 250px;
  width: 100%;
 }

.main-image3 {
  background-image: url('/images/sunrise2.jpg');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;
  background-attachment: fixed;
  height: 250px;
  width: 100%;
 }

/*.main-image2 {
  background-image: url('/images/Anyen_Rinpoche.png'), url('/images/sky_sun2.jpg');
  background-repeat: no-repeat, no-repeat;
  background-position: right top, center top;
  background-size: contain, auto;
  background-attachment: local, fixed;
  height: 300px;
  width: 100%;
 }*/


/*.main-image_Left {
  background-image: url('/images/sky_sun2.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto;
  background-attachment: fixed;
  height: 300px;
  width: 100%;
 }

.main-image_Right {
  background-image: url('/images/Anyen_Rinpoche.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  background-attachment: local;
  height: 300px;
  width: 100%;
 }*/



/*.main-image2 { 
  background-image: url('/images/sunrise2.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 300px;
  width: 100%;
}*/

.page-heading {
	text-align: center;
	padding-top: 10px;
}

.c-background {
  background-image: url('/images/china.png');
  background-color: gold;
  background-blend-mode: lighten;
}

.gold-border {
  border-top: 2px solid gold;
  border-bottom: 2px solid gold;
  padding-top: 20px;
  text-align: center;
}

.maroon-border {
  border-top: 2px solid #aa1b0e;
}

.maroon-border-2 {
  border-top: 1px solid #aa1b0e;
}

.push-bottom {
  margin-bottom: 25px;
}

